// import logo from './logo.svg';
import './css/App.css';
import Header from './components/header';
import headerImage from './img/hamza-mairaj-with-a-cat.jpg';
import berqierlogo from './img/berqier-logo.jpg';
import berqwplogo from './img/berqwp-logo.jpg';

function App() {
  return (
    <>
      <Header />
      <div className='apphome'>
        <div className='container'>
          <div className='leftcol'>
            <p className='hi'>Hi</p>
            <h1>This is <span>Hamza Mairaj</span>, A full stack web developer with 4 years of experience. Specialized in WordPress. A good programmer and a better problem solver.</h1>
            <div className='startups'>
              <h2>Startups</h2>
              <div className='startup-logos'>
                <a target='_blank' href="https://berqier.com"><img src={berqierlogo} alt="Berqier logo" /></a>
                <a target='_blank' href="https://berqwp.com"><img src={berqwplogo} alt="BerqWP logo" /></a>
              </div>
            </div>
            <div className='timeline'>
              <h2>Timeline</h2>
              <div className='timeline-items'>
                <div className='single-year'>
                  <h3>2023</h3>
                  <p>Founded Berqier and created BerqWP</p>
                </div>
                <div className='single-year'>
                  <h3>2022</h3>
                  <p>Became a top-rated WordPress developer</p>
                </div>
                <div className='single-year'>
                  <h3>2021</h3>
                  <p>Joined Upwork and started taking on projects</p>
                </div>
                <div className='single-year'>
                  <h3>2020</h3>
                  <p>Learned WordPress and basics of web development, started freelancing</p>
                </div>
              </div>
            </div>
          </div>

          <div className='rightcol'>
            <img src={headerImage} alt='Hamza Mairaj with a cat' />
          </div>

        </div>
      </div>
    </>
  );
}

export default App;
