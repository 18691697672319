import React, { useState, useEffect } from 'react'

export default function Header() {
    const [isopenmenu, setmenu] = useState(false);

    const togglemenu = () => {
        setmenu(!isopenmenu);

        // console.log(isopenmenu);

        // if (isopenmenu) {
        //     document.body.classList.add('scroll-lock');
        // } else {
        //     document.body.classList.remove('scroll-lock');
        // }

    }

    useEffect(() => {

        if (isopenmenu) {
            document.body.classList.add('scroll-lock');
        } else {
            document.body.classList.remove('scroll-lock');
        }

        return () => {
            document.body.classList.remove('scroll-lock');
        };

    }, [isopenmenu])

    return (
        <>
            <header className="menu">
                <div className="container">
                    <a className="ham-btn" onClick={togglemenu} >
                        {isopenmenu ? (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 384 512"><path fill="white" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                                <span>CLOSE</span>
                            </>
                        ) : (
                            <>
                                <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="2" rx="1" fill="white" />
                                    <rect y="8" width="24" height="2" rx="1" fill="white" />
                                    <rect y="16" width="24" height="2" rx="1" fill="white" />
                                </svg>

                                <span>MENU</span>
                            </>
                        )}

                    </a>
                </div>
            </header>
            <div className={`menu-dropdown ${isopenmenu ? 'active' : ''}`}>
                <div className='container'>
                    <ul>
                        <li><a target='_blank' href="https://berqier.com/contact-us/">Work with me</a></li>
                        <li><a target='_blank' href="mailto:hamza@berqier.com">Say hello: hamza@berqier.com</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}